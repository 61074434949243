exports.linkResolver = function linkResolver(doc) {
  // Route for blog posts
  if (doc.type === 'post') {
    return '/blog/' + doc.uid;
  }
  if (doc.type === 'staff') {
    return '/staff/' + doc.uid;
  }
  if (doc.type === 'issue') {
    return '/issues/' + doc.uid;
  }
  return '/';
}
